import axios from 'axios';

const contactForm = document.getElementById('contact-form');
const submitBtn = document.getElementById('submit-btn');

// Notifications
const notify = ({message, timeout = 3000, type = 'success'}) => {
  const div = document.createElement('div');
  const span = document.createElement('span');
  const template = document.createElement('template');

  div.classList.add(
    'rounded', 
    'fixed', 
    'top-8', 
    'right-8', 
    'py-2', 
    'px-4', 
    'items-stretch', 
    'leading-8', 
    'opacity-0',
    'transition-opacity',
    'font-bold',
    'flex'
    );
  span.textContent = message;
  span.style.marginTop = '2px';
  div.append(span);

  if (type === 'success') {
    div.classList.add('bg-rslight')
    template.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-8 h-8 ml-2">
        <path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" />
      </svg>`;
  } else if (type === 'error') {
    div.classList.add('bg-red-400');
    template.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-8 h-8 ml-2">
        <path d="M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
      </svg>`;
  }
  div.append(template.content);

  document.body.append(div);
  requestAnimationFrame(() => {
    div.classList.remove('opacity-0');
  });

  setTimeout(() => {
    div.remove();
  }, timeout);
}

// Contact form
const handleSubmit = async (ev) => {
  ev.preventDefault();
  const name = document.getElementById('name').value;
  const company = document.getElementById('company').value;
  const email = document.getElementById('email').value;
  const message = document.getElementById('message').value;
  const botField = document.getElementById('honeypot').value;

  if (!!botField) {
    contactForm.reset();
    return notify({message: 'Oj, det ser ut som att du är en bot 🤖', type: 'error'});
  }

  try {
    submitBtn.disabled = true;
    await axios.post(
      '/.netlify/functions/sendgrid',
      {
        message: `
          Naam: ${name} <br>
          Bedrijfsnaam: ${company} <br>
          E-mail: ${email} <br>
          <br>
          Bericht: <br>
          ${message}
        `
      }
    );
    notify({message: 'Ditt meddelande har skickats.', type: 'success'});
    contactForm.reset();
    
  } catch (error) {
    notify({message: 'Hoppsan, ditt meddelande skickades inte.', type: 'error'});
  } finally {
    submitBtn.disabled = false;
  }
}

contactForm.addEventListener('submit', handleSubmit);